
import Vue from 'vue';
import { LocButton, LocEmoji, LocLazy } from '@localazy/components';
import LineSimple from '@/assets/svg/scribbles/LineSimple.vue';
import AutomatedBg from '@/assets/svg/tools/AutomatedBg.vue';
import SvgTopographyLines from '@/assets/svg/SvgTopographyLines.vue';
import TranslatorBadge from '@/assets/svg/badges/TranslatorBadge.vue';

export default Vue.extend({
  name: 'AutopilotFeatured',
  components: {
    LocButton,
    LocEmoji,
    LocLazy,
    LineSimple,
    AutomatedBg,
    SvgTopographyLines,
    TranslatorBadge
  },
  props: {
    badges: {
      type: Boolean,
      default: false
    }
  }
});
